import React from 'react';
import { BrowserRouter as Router, Routes, Route, useLocation } from 'react-router-dom';
import Navbar from './components/Navbar';
import Banner from './components/Banner';
import Services from './components/Services';

import About from './components/About';
import Chatbot from './components/Chatbot'; // Import Chatbot


// Import the Landing Page component
import LandingPage from './components/LandingPage';

// Import the page components for each route
import Send from './pages/Send';
import Receive from './pages/Receive';
import FlightBooking from './pages/FlightBooking';
import BusBooking from './pages/BusBooking';
import TrainBooking from './pages/TrainBooking';
import Airtime from './pages/Airtime';
import Electricity from './pages/Electricity';
import Betting from './pages/Betting';
import BettingMatches from './pages/BettingMatches'; // Import BettingMatches
import Utilities from './pages/Utilities';
import GiftCards from './pages/GiftCards';
import Exchange from './pages/Exchange';
import BuyCurrencies from './pages/BuyCurrencies';
import Profile from './pages/Profile';
import Accounts from './pages/Accounts'; // Import Accounts
import Crypto from './pages/Crypto'; // Import Crypto
import Prepaid from './pages/Prepaid'; // Import Prepaid
import Sendmoney from './pages/Sendmoney'; // Import Sendmoney

const AppContent = () => {
  const location = useLocation();

  const isMainOrLanding = location.pathname === '/' || location.pathname === '/main';

  return (
    <>
      {/* Show Navbar only if the path is not "/" */}
      {location.pathname !== '/' && <Navbar />}

      <Routes>
        {/* Landing Page route */}
        <Route path="/" element={<LandingPage />} />

        {/* Main App Routes */}
        <Route path="/main" element={
          <>
            <Banner />
            <Services />
            <About />
          </>
        } />

      <Route path="/main/accounts" element={<Accounts />} />
      <Route path="/main/send" element={<Sendmoney />} />
      <Route path="/main/crypto" element={<Crypto />} />
      <Route path="/main/prepaid" element={<Prepaid />} />

        {/* Bank routes */}
        <Route path="/main/send" element={<Send />} />
        <Route path="/main/receive" element={<Receive />} />

        {/* Travel routes */}
        <Route path="/main/flight-booking" element={<FlightBooking />} />
        <Route path="/main/bus-booking" element={<BusBooking />} />
        <Route path="/main/train-booking" element={<TrainBooking />} />

        {/* Purchase routes */}
        <Route path="/main/airtime" element={<Airtime />} />
        <Route path="/main/electricity" element={<Electricity />} />
        <Route path="/main/betting" element={<Betting />} />
        <Route path="/main/utilities" element={<Utilities />} />
        <Route path="/main/gift-cards" element={<GiftCards />} />

        {/* Forex routes */}
        <Route path="/main/exchange" element={<Exchange />} />
        <Route path="/main/buy-currencies" element={<BuyCurrencies />} />

        {/* Betting Matches route */}
        <Route path="/main/betting-matches" element={<BettingMatches />} />

        {/* Profile */}
        <Route path="/main/profile" element={<Profile />} />
      </Routes>

      {/* Conditionally render WalletBalance and Chatbot */}
      {!isMainOrLanding && (
        <>
          
          <Chatbot />
        </>
      )}
    </>
  );
};

const App = () => (
  <Router>
    <AppContent />
  </Router>
);

export default App;

